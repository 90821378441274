import React from "react"
import { Row, Col } from "reactstrap"

import Heading from "src/components/index/Heading"

import composeTemplate from "src/assets/img/screenshots/compose-template.png"

const ComposeLettersOverview = () => (
  <section>
    <Row className="u-centerFlex">
      <Col lg={6} md={12} className="SplitContentRow-text">
        <Row>
          <Heading title="Powerful templating">
            Save hours of time with patient letter templates
          </Heading>
        </Row>
        <p className="u-midGrey">
          There’s no need to be writing every patient letter from scratch.
          Carebit’s smart templates automatically generate the header, footer,
          addressee’s name and address, the date of the letter, the patient’s
          details and even your signature and title.
        </p>
        <p className="u-midGrey">
          These templates are linked to the service booked, so you can create
          templates for a specific service (e.g. ‘Followup consultation with
          PSA’) and the template will be loaded automatically when you come to
          write the letter.
        </p>
        <p className="u-midGrey">
          All you need to do is add in the extra information you want to
          include.
        </p>
      </Col>
      <Col
        lg={{ size: 5, offset: 1 }}
        md={{ size: 12 }}
        className="SplitContentRow-graphic"
      >
        <img
          alt="Carebit Compose - customisable letter templates"
          src={composeTemplate}
          className="u-outline"
          style={{ width: "100%" }}
        />
      </Col>
    </Row>
  </section>
)

export default ComposeLettersOverview
