import React from "react"
import { Container, Row, Col, Badge, Button } from "reactstrap"

import GenericAutomationChartContainer from "src/containers/charts/GenericAutomationChartContainer"

import Layout from "src/components/Layout"
import Seo from "src/components/Seo"
import Heading from "src/components/index/Heading"
import ComposeLettersOverview from "src/components/features/compose-letters/ComposeLettersOverview"
import ViewMoreFeatures from "src/components/features/ViewMoreFeatures"

import StyledIllustration from "src/components/features/StyledIllustration.jsx"
import TestimonialCard from "src/components/TestimonialCard"

import chrisAvatar from "src/assets/img/testimonials/chris-medium600.jpg"
import openedIllustration from "src/assets/img/icons/undraw/opened-gradient.svg"
import composeCreateBooking from "src/assets/video/compose-create-booking.gif"
import patientPortalLetters from "src/assets/video/patient-portal-letters.gif"
import composeTranscription from "src/assets/video/compose-transcription.gif"
import composeSendLetter from "src/assets/img/screenshots/compose-send-letter.png"

import examplePatientLetter from "src/assets/pdf/example-patient-letter.pdf"

const FeaturePracticeManagement = () => {
  return (
    <Layout>
      <Seo
        title="Carebit: Features | Compose letters"
        pathname="/features/compose-letters"
        description="Carebit's smart templates mean you don't have to hire a typist to write your letters. Drafting, sending and booking followups can be done in a matter of minutes."
      />
      <div className="u-centerFlex u-textCenter Hero">
        <Container>
          <Row>
            <Col
              lg={{ size: 6, offset: 3 }}
              md={{ size: 10, offset: 1 }}
              style={{ zIndex: 2, padding: "120px 40px 200px", color: "white" }}
            >
              <Badge color="info" className="u-marginBottom20">
                Features
              </Badge>
              <h1>Compose letters</h1>
              <p className="u-whiteTranslucent">
                Using Carebit’s Compose feature makes writing letters a breeze.
                It’s cheaper, faster, more accurate, increases your revenue and
                patients receive their letters instantly, often before they’ve
                even left your office.
              </p>

              <p className="u-whiteTranslucent">
                Built with extensive research and development in a real clinic
                that sees hundreds of patients a year, you’ll never want to go
                back to the old way of dictating and posting letters.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <Row>
            <Col md={{ size: 10, offset: 1 }}>
              <section>
                <Row className="u-centerFlex SplitContentRow">
                  <Col lg={6} md={12} className="SplitContentRow-text">
                    <Row>
                      <Heading title="Save money">
                        Save thousands of pounds a year on typist costs
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      With its automation and voice-to-text functionality, using
                      Compose to write your clinic letters saves significant
                      amounts of time and money.
                    </p>
                    <p className="u-midGrey">
                      With Carebit’s smart templates and integrated sending by
                      free secure email (including directly to NHS recipients),
                      the cost to your practice is dramatically reduced.
                    </p>
                  </Col>
                  <Col lg={6} md={12} className="SplitContentRow-graphic">
                    <GenericAutomationChartContainer
                      chartData={[
                        { x: "Carebit", y: 12 },
                        { x: "MS Word", y: 48 },
                      ]}
                      staticText="Using Carebit typically takes a quarter of the time"
                    />
                  </Col>
                </Row>
              </section>

              <section>
                <Row className="u-centerFlex">
                  <Col lg={6} md={12} className="SplitContentRow-graphic">
                    <StyledIllustration src={openedIllustration} />
                  </Col>
                  <Col lg={6} md={12} className="SplitContentRow-text">
                    <Row>
                      <Heading title="Improved accuracy">
                        No more back-and-forth
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      With Carebit Compose, the doctor can even write the letter
                      themselves using templates, so they know the letter is
                      100% accurate and exactly as they want it. Wave goodbye to
                      multiple rounds of editing and corrections.
                    </p>

                    <div style={{ marginTop: "40px" }}>
                      <a
                        href={examplePatientLetter}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Button color="secondary" size="md">
                          View example patient letter &rarr;
                        </Button>
                      </a>
                    </div>
                  </Col>
                </Row>
              </section>

              <ComposeLettersOverview />

              <section>
                <Row className="u-centerFlex">
                  <Col lg={5} md={12} className="SplitContentRow-graphic">
                    <img
                      alt="Carebit Compose - create followup bookings instantly"
                      src={composeCreateBooking}
                      className="u-outline"
                      style={{ width: "100%" }}
                    />
                  </Col>
                  <Col
                    lg={{ size: 6, offset: 1 }}
                    md={{ size: 12 }}
                    className="SplitContentRow-text"
                  >
                    <Row>
                      <Heading title="Convenient and instant">
                        Book in patients for their next appointment with one
                        click
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      A key part to any clinic workflow is the arranging of
                      followup appointments. With the click of a button and in a
                      fraction of a second, Carebit intelligently scans your
                      availability settings across locations and services to
                      book the patient into the best slot. Without the secretary
                      having to do anything, the patient receives a booking
                      confirmation email (and an invoice too if they are
                      self-pay).
                    </p>

                    <p className="u-midGrey">
                      It even adds a short sentence mentioning the booking into
                      the letter, so all recipients are aware of the next steps.
                    </p>
                  </Col>
                </Row>
              </section>

              <section>
                <Row className="u-centerFlex">
                  <Col lg={6} md={12} className="SplitContentRow-text">
                    <Row>
                      <Heading title="A better patient experience">
                        Patients receive their letter instantly
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      In a huge leap forward for their experience with you, the
                      patient always receives access to a copy of the letter. An
                      email notification is sent to them as soon as you send the
                      letter, inviting them to view it as a PDF in the Patient
                      Portal. Often, this email is sent as they are leaving your
                      clinic - they’ll be impressed and you’ll appear efficient
                      and proactive.
                    </p>
                  </Col>
                  <Col
                    lg={{ size: 5, offset: 1 }}
                    md={{ size: 12 }}
                    className="SplitContentRow-graphic"
                  >
                    <img
                      alt="Carebit - patients can view letters in the patient portal"
                      src={patientPortalLetters}
                      className="u-outline"
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
              </section>

              <section>
                <Row className="u-centerFlex">
                  <Col lg={5} md={12} className="SplitContentRow-graphic">
                    <img
                      alt="Carebit Compose - transcribe voice to text"
                      src={composeTranscription}
                      className="u-outline"
                      style={{ width: "100%" }}
                    />
                  </Col>
                  <Col
                    lg={{ size: 6, offset: 1 }}
                    md={{ size: 12 }}
                    className="SplitContentRow-text"
                  >
                    <Row>
                      <Heading title="Just talk">
                        Voice to text transcription
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      You don’t have to type the letter. If you prefer, you can
                      use Carebit’s free transcription feature to dictate the
                      letter. As you speak, your words appear on screen and you
                      can start or stop transcription at the click of a button.
                    </p>
                  </Col>
                </Row>
              </section>

              <section>
                <Row className="u-centerFlex">
                  <Col lg={6} md={12} className="SplitContentRow-text">
                    <Row>
                      <Heading title="Encrypted letters">
                        Send the letter securely to colleagues
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      Once written, Carebit makes it really easy to select the
                      recipients and send the letter. By default, all of the
                      patient’s connections (GP, referring consultant etc) and
                      the patient are selected as recipients, but you can easily
                      change the addressee and the transmission method at the
                      click of a button. And if you change the addressee, the
                      address and title of the addressee changes instantly in
                      the letter as well.
                    </p>

                    <p className="u-midGrey">
                      If you select ‘Email’ as the transmission method, the
                      recipient will receive a secure email which they can use
                      to log into Carebit to view the letter, keeping you fully
                      compliant with GDPR.
                    </p>
                  </Col>
                  <Col
                    lg={{ size: 5, offset: 1 }}
                    md={{ size: 12 }}
                    className="SplitContentRow-graphic"
                  >
                    <img
                      alt="Carebit Compose - send letter to multiple recipients"
                      src={composeSendLetter}
                      className="u-outline"
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
              </section>
            </Col>
          </Row>
        </Container>

        <div className="Index-gradient">
          <div className="ColouredDivider Index-dividerBlueGreen">
            <Container>
              <section>
                <TestimonialCard
                  author="Professor Christopher Eden"
                  authorSubtext="Leading robotic radical prostatectomy expert, Santis"
                  avatarSrc={chrisAvatar}
                >
                  <p>
                    “Compose allows the doctor to rapidly edit an existing
                    letter template or to dictate and have transcribed in real
                    time an entirely new letter, having just seen the patient
                    and to book the next appointment or book that patient in for
                    a test or intervention, before the letter is emailed to the
                    GP, patient and any other interested parties. Not only does
                    it ensure that the content is accurate but it also saves a
                    good deal of time and money.”
                  </p>
                </TestimonialCard>
              </section>
            </Container>
          </div>
        </div>

        <ViewMoreFeatures />
      </div>
    </Layout>
  )
}

export default FeaturePracticeManagement
